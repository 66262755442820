/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import React, { Component, Suspense, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import _, { isNull, isUndefined } from 'lodash';
import {
  SocialFeedsContainer, /*ContentContainer, InputFieldContainer,*/
  StyledTitle, SideBarContainer, TileContainer, TitleContent, SideBarContent,
  StyledImage, NotificationDescription, NoComments, /*Layout,*/
  Profile, NoFriendRequests, NoActivityFeeds, FeedsContainer, /*CommentSection,*/ ScrollableFeedsContainer, AssessmentContainer,
  TipInformation, ProfileContainer, /*ButtonWrapper, PostWrapper, PhotoWrapper, RecognitionWrapper, ConnectDevice, CommentDiv  LinkContainer, Link, LinkTab, PictureDiv,*/ CommentSectionNew, InputFieldContainerNew,
  PictureDivNew,
  ButtonWrapperNew, PostWrapperNew, MainSendNew, CoreValueMain, CoreValue, DailyTipCard, CoreValueSideBarContainer, MainWrapper
} from './styles';
import Waiting from '../Waiting';
import SingleFeed from './SingleFeed';
// import Loading from '../Loading';
import { ImageUrl, imageErrorMessage } from '../../utils/constants';
import { resetOrientation, getOrientation, checkImage, applyHighlights, convertUnicode } from '../../utils/methods';
import Image from '../Image';
import { withTranslation } from 'react-i18next';
import { SetDailyGoal, getBirthdaysList } from '../../redux/actions';
import BirthdayWishCard from './birthdayWishCard';
import WarningPopup from './warningRecognition';
import {
  getUserProfileImage,
  addCommentReplyOnPost,
  addSocialInspiration,
  getCommentsOnPost,
  getUsersToShoutout,
  getSuggestionsList,
  getPostLikeDetails,
  userBirthdayAndAnniversary,
  getUser,
  getCompanyExemplifiesValues,
  addBuddy,
  getListOfThingsToCompleteAPI,
  getProfileInputActivityGoals
} from '../../redux/actions';
const EditPostPopUp =   lazy(() => import('../EditPostPopUp'));
const DeletePostPopUp = lazy(() => import('../DeletePostPopup/deletePostConfirmation'));
import SocialFeedTextarea from './SocialFeedTextAreaNew';
import { HraIcon } from '../DailyTip/styles';
import { getListOfConnectedDevices, revokeDevice, getWorkAnniversary, getAmigoBirthday } from '../../redux/actions';
// import CommentsV3 from '../CommentsV3';
import ConnectDeviceSideBar from '../ConnectDeviceSideBar';
import PhotoAndPostFlow from '../PhotoAndPostFlow';
import PhotoAndVideoPostFlow from '../PhotoAndPostFlow/photosVideosPostPopup'
import PeerRecognition from '../PeerRecognition';
const LikePopUp = lazy(() => import('../LikePopUp'));
import BirthdaySideBar from '../BirthdaySideBar';
// const AddBuddyPopUp = lazy(() => import('./AddBuddyPopUp'));
import SuggestionForYouSocial from '../SuggestionForYou/suggationForYouSocial';
import TaskToComplete from '../ContentV2/SideBarContiner/TaskToComplete';
import { GetBirthdayAndAnniversaryCardStatus } from '../../redux/constants/apiConstants';
import { BASE_URL } from '../../utils/config';
import LazyImage from '../common/LazyImage/LazyImage';
import BirthdayWishPopup from './birthdayWishPopup';
import AnniversaryWishCard from './anniversaryWishCard';
import { getInspirationPost } from '../../redux/actions/socialActions';
import ViewCommentModal from '../ViewCommentModal';
import RecognitionDescription from './recognitonDescription';
// import moment from 'moment';
import DOMPurify from 'dompurify';

class SocialFeeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: [],
      showModal: '',
      inspirationQuote: '',
      showCommentsFor: '',
      pageNumber: 1,
      showEditDeletePopup: '',
      editDetails: '',
      editAndDelete: false,
      isUploadedImage: false,
      refreshFileReader: false,
      mentionedUsers: [],
      shoutoutType: 0,
      users: [],
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      taggedUser: '',
      postDisabled: true,
      exemplifyID: null,
      ShowEmojiContainer: false,
      displayEmojiPicker: false,
      showConnectDevice: false,
      showPhotoPostModal: false,
      showPeerModal: false,
      showLikePostModal: false,
      feedNumber: 0,
      activeLink: 0,
      showBirthdaySideBarModal: false,
      shoutOutData: {},
      tabIndex: 0,
      showRecognitionModal: false,
      value: null,
      id: null,
      image: "",
      feedNo: null,
      editPostStatus: false,
      showAddBuddy: false,
      showBirthdayContainer: true,
      showAnniversaryContainer: true,
      addCommentWithImageStatus: false,
      feedIdForComment: '',
      commentId: '',
      showThreeComments: false,
      showViewCommentModal: false,
      specialFeed: {},
      friendStatus: '',
      handleAPICalling: true,
      fullMonth: '',
      month: '',
      showDeviceModal: false,
      user: null,
      filterType: 'Company',
      showPhotoVideoPostModal:false,
      imgSrcArray:[],
      isBirthdayCard:0,
      isAnniversaryCard:1,
      showBirthdayWishPopup:false,
      showAnniversaryWishPopup:false,
      showWarningRecognition: false,
      selectedFeedId: null,
      showCommentModalNotification: false,
      showModalRecogniton: false,
      recognitionData: [],
      showRecognitionSingle: false
    }
    this.myref = React.createRef();
  }

  handleClick1 = (event) => {
    if (!isNull(this.myref) && !isUndefined(this.myref) && !isNull(this.myref.current) && !isUndefined(this.myref.current) && !(this.myref && this.myref.current && this.myref.current.contains(event.target))) {
      this.setState({
        showConnectDevice: false,
        showBirthdaySideBarModal: false
      });
    }
  };

  handleClick = (event) => {
    if (!isNull(this.myref) && !isUndefined(this.myref) && !(this.myref && this.myref.current && this.myref.current.contains(event.target))) {
      this.setState({ ShowEmojiContainer: false });
    }
    if (!isNull(this.myref) && !isUndefined(this.myref) && !isNull(this.myref.current) && !isUndefined(this.myref.current) && !(this.myref && this.myref.current && this.myref.current.contains(event.target))) {
      this.setState({
        showConnectDevice: false,
        showBirthdaySideBarModal: false
      });
    }
  };

  componentDidMount() {
    // if (this.props.history.location.state != undefined) {
    //   this.showBirthdayCardModal()
    // }
    this.setState({ showCommentModalNotification: this.props.history.location.state });
    if (this.props.history.location.state) {
      this.props.getInspirationPost(this.props.history.location.state);
      this.props.fetchCommentsOnPost(this.props.history.location.state);
      this.setState({
        showViewCommentModal: true
      })
    }
 
    document.addEventListener("mousedown", this.handleClick1);
    const { listOfThingToComplate, fetchListOfThingsToComplete, profileImage, fetchUserProfileImage, getSuggestionsList, userBirthdayAndAnniversary, getCompanyExemplifiesValues, getAmigoBirthday, getWorkAnniversary, getProfileInputActivityGoals, getBirthdaysList } = this.props;
    getSuggestionsList();
    getProfileInputActivityGoals();
    getBirthdaysList();
    if (!profileImage) {
      fetchUserProfileImage();
    }
    if (isEmpty(listOfThingToComplate)) {
      fetchListOfThingsToComplete();
    }
    // fetchConnectedDevices();
    userBirthdayAndAnniversary();
    // const user = async() => await getUser();
    getCompanyExemplifiesValues();
    getAmigoBirthday();
    getWorkAnniversary();
    this.fetchAnniversaryCardStatus(this.props.companyId);
  }


  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  componentDidUpdate(prevProps) {
    if (this.state.showViewCommentModal!=true && this.props.history.location.state!=null) {
      this.props.getInspirationPost(this.props.history.location.state);
      this.props.fetchCommentsOnPost(this.props.history.location.state);
      this.setState({
        showViewCommentModal: true,
        showCommentModalNotification: this.props.history.location.state
      })
    }
    if (prevProps.devicesDisconnected !== this.props.devicesDisconnected) {
      if (this.props.devicesDisconnected.length && !this.props.dailyTip.show_tip) {
        this.setState({
          deviceNotificationModal: true
        })
      }
    }
    if (this.props.feeds !== prevProps.feeds || this.props.socialPosts !== prevProps.socialPosts) {
      this.setState({
        // storeFeeds: [...this.props.feeds],
      });
      let index = -1;
      if (!isEmpty(this.state.specialFeed) && this.props.socialPosts && Array.isArray(this.props.socialPosts) && this.props.socialPosts.length > 0) {
        index = this.props.socialPosts.findIndex((data) => data.id === this.state.specialFeed.id);
        this.setState({
          // storeFeeds: [...this.props.feeds],
          specialFeed: index !== -1 ? this.props.socialPosts[index] : {}
        });
      }
    }
  }

  fetchAnniversaryCardStatus = async(companyId)=>{
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url =
    BASE_URL + GetBirthdayAndAnniversaryCardStatus + companyId;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } }).then(
      (res) => {
        res.json().then((data) => {
          if(res.status === 200){
            this.setState({ isBirthdayCard:data.data[0].show_birthday_card,isAnniversaryCard:data.data[0].show_work_anniversary_card });

          }else{
            // toast.error(data.data.message);
          }

        }).catch(()=>{
          // toast.error(error.message);
        });
      }
    ).catch(()=>{
      // toast.error(error.message);
    });
  };

  openEditAndDelete = () => {
    this.setState({
      editAndDelete: !this.state.editAndDelete
    })
  };

  showHideBirthdayPopup = () => {
    this.setState({
      showBirthdayWishPopup: !this.state.showBirthdayWishPopup
    })
  };

  recognitionWarningPopup = () => {
    this.setState({
      showWarningRecognition: !this.state.showWarningRecognition
    })
  };

  showRecognitionPopup = (data) => {
    this.setState({
      showModalRecogniton: !this.state.showModalRecogniton,
      recognitionData: data
    })
  };

  onCloseRecognition = () => {
    this.setState({
      showModalRecogniton: !this.state.showModalRecogniton,
      recognitionData: []
    })
  };

  showHideAnniversaryPopup = () => {
    this.setState({
      showAnniversaryWishPopup: !this.state.showAnniversaryWishPopup
    })
  };

  toggleConnectDevices = () => {
    
    this.setState((prev) => ({
      showConnectDevice: !prev.showConnectDevice
    }));
    console.log("\n showConnectDevice :- ", this.state.showConnectDevice);

  };

  changeEditDeletePopup = (value, feed) => {
    this.setState({ editDetails: feed }, () => {
      this.setState({ showEditDeletePopup: value });
    });
  };
  editPostModel = (feed) => {
    let textDiv = document.createElement("div");
    if (feed.description) {
      // textDiv.innerHTML = convertUnicode(feed.description).split('\\n').join('</br>');

      const sanitizedDescription = DOMPurify.sanitize(convertUnicode(feed.description).split('\\n').join('</br>'));
      const fragment = document.createRange().createContextualFragment(sanitizedDescription);
      textDiv.appendChild(fragment);
    }
    let text = textDiv.textContent || textDiv.innerText || "";
    feed.description = text;
    this.setState({ editDetails: feed, editPostStatus: 1 }, () => {
      this.setState({ showPhotoPostModal: true });
    });
  };

  onHideEditDeletePopup = () => {
    this.setState({
      showEditDeletePopup: ''
    }, () => {
      this.openEditAndDelete();
    })
  };

  onChange = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({
            imgSrc: [reader.result],
            showModal: 'postModal',
            refreshFileReader: true
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: [baseImage],
                refreshFileReader: false
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('uploadPhoto') && (document.getElementById('uploadPhoto').value = '');
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('uploadPhoto') && (document.getElementById('uploadPhoto').value = '');
    }
  };

  getCommentsForPost = (id) => {
    const { fetchCommentsOnPost } = this.props;
    this.setState({
      showCommentsFor: id,
      showThreeComments: true
    });
    fetchCommentsOnPost(id);
  };

  getCommentsForPostModal = (id) => {
    const { fetchCommentsOnPost } = this.props;
    fetchCommentsOnPost(id);
  };

  onScroll = (e) => {
    const { pageNumber } = this.state;
    const { count, feeds } = this.props;
    const scrolled = Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10));
    const cssScroll = Math.round(e.target.clientHeight);
    const persent = Math.round((cssScroll / scrolled) * 100);
    const bottom = persent === 100;
    // const bottom =  persent>= 95
    let feedsData = feeds.response ? feeds.response : feeds;
    if (bottom && feeds && feedsData.length < count) {
      this.setState({
        pageNumber: pageNumber + 1
      }, () => {
        this.props.loadMoreFeeds(this.state.pageNumber);
      });
    }
  };

  feeding = (key) => {
    this.setState({
      feedNumber: key
    });
  };

  renderViewCommentAndGetComment = (feed, id) => {
    this.setState((prev) => ({
      showViewCommentModal: !prev.showViewCommentModal,
      specialFeed: feed,
      selectedFeedId: id
    }))
    this.props.getInspirationPost(id) ;
    this.getCommentsForPostModal(id)
    this.handleScrollTrigger();
  }

  renderViewCommentModal = () => {
    this.setState((prev) => ({
      showViewCommentModal: !prev.showViewCommentModal,
      specialFeed: {}
    }))
    this.handleScrollTrigger();
  }

  displayDeviceModal = () => {
    this.props.fetchConnectedDevices();
    this.setState({ showDeviceModal: true })
  };

  renderFeeds = () => {
    const { /*showCommentsFor,*/ editAndDelete, /*showThreeComments, isBirthdayCard, isAnniversaryCard*/ } = this.state;
    const { /*isLoading,*/ comments, replySuccess, postReplyOnComment, profileImage, history, enableTrigger, postLikeDetails, socialCallBack, t, feeds, /*birthdayList, anniversaryList, userCompany*/ } = this.props;
    const allPosts =  feeds && (feeds.response && feeds.response.length > 0 ? feeds.response : feeds.length > 0 && feeds);
    // let showBirthdayContainer = true;
    // let showAnniversaryContainer = true;
    // if (birthdayList && birthdayList.today_birthday && birthdayList.today_birthday.length === 0 && birthdayList.upcoming_birthday && birthdayList.upcoming_birthday.length === 0 && birthdayList.recent_birthday && birthdayList.recent_birthday.length === 0) {
    //   showBirthdayContainer = false;
    // }
    // if (anniversaryList && anniversaryList.today_anniversary && anniversaryList.today_anniversary.length === 0 && anniversaryList.upcoming_anniversary && anniversaryList.upcoming_anniversary.length === 0 && anniversaryList.recent_anniversary && anniversaryList.recent_anniversary.length === 0) {
    //   showAnniversaryContainer = false;
    // }
    return (
      <div>
        {
          allPosts?.length > 0 ?
            <ScrollableFeedsContainer>
              {
                allPosts.map((data, index) => {
                  let feed;
                  if (data.post_data) {
                    feed = data.post_data
                  }
                  return (
                    feed ?<FeedsContainer key={`${index}-${feed.id}`} >
                      {/* {index>0&&index<2&& showBirthdayContainer && (!isNull(userCompany.companyStartDate) && userCompany.companyStartDate.user_birthday_notify === 1) && isBirthdayCard === 1 && 
                        <BirthdayWishCard callback={this.showHideBirthdayPopup}/>}
                      {index>1&&index<4&& showAnniversaryContainer && (!isNull(userCompany.companyStartDate) && userCompany.companyStartDate.show_work_anniversary === 1) && isAnniversaryCard === 1 &&
                        <AnniversaryWishCard callback={this.showHideAnniversaryPopup}/>} */}
                      <SingleFeed
                        feed={feed}
                        specialFeed={this.state.specialFeed}
                        description={feed.description}
                        taggedUsers={feed.shoutout_user_name}
                        getCommentsForPost={this.getCommentsForPost}
                        getCommentsForPostModal={this.getCommentsForPostModal}
                        changeEditDeletePopup={this.changeEditDeletePopup}
                        editAndDelete={editAndDelete}
                        openEditAndDelete={this.openEditAndDelete}
                        profileImage={profileImage}
                        updationList="response"
                        commentCount={feed.comment_count}
                        history={history}
                        enableTrigger={enableTrigger}
                        key={`feed-${index}-${feed.id}-single-post`}
                        onChange={this.onChange}
                        handleFeed={this.feeding}
                        handleModal={this.handleLikePostModal2}
                        feedNumber={this.state.feedNumber}
                        data={postLikeDetails}
                        exemplifiesValues={this.props.exemplifiesValues}
                        showRecognition={this.showRecognition}
                        value={this.state.value}
                        id={this.state.id}
                        image={this.state.image}
                        feedNo={this.feedNo}
                        updateFeedsLike={this.props.updateFeedsLike}
                        storedFeeds={feeds}
                        editPost={this.editPostModel}
                        socialCallBack={socialCallBack}
                        handlePhotoPostModal={this.handlePhotoPostModal}
                        comments={comments}
                        postReply={postReplyOnComment}
                        replySuccess={replySuccess}
                        showViewCommentModal={this.state.showViewCommentModal}
                        renderViewCommentModal={this.renderViewCommentModal}
                        renderViewCommentAndGetComment={this.renderViewCommentAndGetComment}
                        handleScrollTrigger={this.handleScrollTrigger}
                        selectedFeedId={this.state.selectedFeedId}
                      />
                      {/* {this.state.showCommentsFor === feed.id &&
                          (isLoading ? <Loading isHorizontal /> :
                            (isEmpty(comments) ? <NoComments>{t('No Comments to display')}</NoComments> :
                              <CommentsV3
                                comments={comments}
                                postReply={postReplyOnComment}
                                replySuccess={replySuccess}
                                id={feed.id}
                                history={history}
                                enableTrigger={enableTrigger}
                                onChange={this.onChange}
                                feed={feed}
                                handlePhotoPostModal={this.handlePhotoPostModal}
                                showThreeComments={this.state.showThreeComments}
                                fromSocialFeed={true}
                                renderViewCommentModal={this.renderViewCommentModal}
                              />))} */}
                      {/* {
                          feed.comment_count > 3 && showCommentsFor===feed.id && showThreeComments?null:
                            feed.comment_count > 3 ? <CommentDiv >{<div onClick={() => this.getCommentsForPost(feed.id)}>{t("View All Comments")}</div>}</CommentDiv> : null} */}
                    </FeedsContainer> 
                      : null
                  )
                })
              }
            </ScrollableFeedsContainer> : <NoComments>{t("No posts in this section")}</NoComments>

        }
      </div>
    )
  };

  addInspiration = (e) => {
    this.setState({
      inspirationQuote: e.target.value,
    });
  };

  updateText = (text, userId, taggedUserName, bool) => {
    const { userIdArray, users, taggedUser } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if (!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      inspirationQuote: text,
      userIdArray: userIdArr,
      users: userNameArr,
      taggedUser: taggedUsers,
      showGivenShoutout: bool
    });
  };

  updateShoutoutArray = (mentionedUsers, text, image, exemplify_id) => {

    this.setState({
      mentionedUsers: mentionedUsers,
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplify_id ? exemplify_id : null,
      exemplifyID: exemplify_id ? exemplify_id : null
    });
  };

  onRemoveShoutout = (array) => {
    this.setState({
      [array]: [],
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      shoutoutType: 0,
      mentionedUsers: []
    });
  };

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: []
    });
  };

  showEmojiPicker = () => {
    this.setState((prev) => ({
      displayEmojiPicker: !prev.displayEmojiPicker
    }))
  }

  renderInputField = (placeholder, UpdateStatus, isCommentOrReply, profileImage) => (
    <InputFieldContainerNew UpdateStatus={UpdateStatus} padding="10px" height={'100px'} onClick={() => this.handlePhotoPostModal()}>
      <PictureDivNew>
        <img src={`${ImageUrl}/${profileImage}`} />
      </PictureDivNew>
      <SocialFeedTextarea
        userCompany={this.props.userCompany}
        addInspiration={this.addInspiration}
        inspirationQuote={this.state.inspirationQuote}
        updateText={this.updateText}
        users={this.state.users}
        userIdArray={this.state.userIdArray}
        placeholder={placeholder}
        className={'CustomTextAreaField2'}
        showGivenShoutout={this.state.showGivenShoutout}
        updateShoutoutArray={this.updateShoutoutArray}
        emptyShoutoutArray={this.emptyShoutoutArray}
        onRemoveShoutout={this.onRemoveShoutout}
        isCommentOrReply={isCommentOrReply}
        shoutoutText={this.state.shoutoutText}
        shoutoutImage={this.state.shoutoutImage}
        taggedUser={this.state.taggedUser}
        onRemoveUser={this.onRemoveUser}
        enableTrigger={this.props.enableTrigger}
        ShowEmojiContainer={this.state.ShowEmojiContainer}
        myref={this.myref}
        showEmojiPicker={this.showEmojiPicker}
        displayEmojiPicker={this.state.displayEmojiPicker}
        popup={this.handlePhotoPostModal}
      />
      {this.state.inspirationQuote && this.state.inspirationQuote !== null ? (
        <MainSendNew onClick={this.addPost}>
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
            <circle cx="21" cy="21" r="21" fill="#0D4270" />
            <g clipPath="url(#clip0)">
              <path d="M31.7575 21.0237L15.7614 11.1406C15.5399 11.0053 15.2569 11.0298 15.0477 11.1898C14.8509 11.3621 14.777 11.6451 14.8632 11.8912L18.247 20.9375L23.4395 21.5527L18.247 22.1679L14.8632 31.216C14.777 31.4621 14.8509 31.7451 15.0477 31.9174C15.253 32.077 15.5513 32.0978 15.7614 31.9665L31.7575 22.0818C31.942 21.9588 32.0528 21.7619 32.0528 21.5527C32.0528 21.3436 31.942 21.1467 31.7575 21.0237Z" fill="url(#paint0_linear)" />
              <path d="M26.5156 20.9375H18.2469L18.4806 21.5527L18.2469 22.168H26.5156C26.8602 22.168 27.1309 21.8973 27.1309 21.5527C27.1309 21.2082 26.8602 20.9375 26.5156 20.9375ZM15.4414 20.9375H11.668C11.3279 20.9375 11.0527 21.2127 11.0527 21.5527C11.0527 21.8928 11.3279 22.168 11.668 22.168H15.4414C15.7815 22.168 16.0566 21.8928 16.0566 21.5527C16.0566 21.2127 15.7815 20.9375 15.4414 20.9375ZM14.2109 23.3984H11.668C11.3279 23.3984 11.0527 23.6736 11.0527 24.0137C11.0527 24.3537 11.3279 24.6289 11.668 24.6289H14.2109C14.551 24.6289 14.8262 24.3537 14.8262 24.0137C14.8262 23.6736 14.551 23.3984 14.2109 23.3984ZM11.668 19.707H14.2109C14.551 19.707 14.8262 19.4319 14.8262 19.0918C14.8262 18.7517 14.551 18.4766 14.2109 18.4766H11.668C11.3279 18.4766 11.0527 18.7517 11.0527 19.0918C11.0527 19.4319 11.3279 19.707 11.668 19.707Z" fill="url(#paint1_linear)" />
            </g>
            <defs>
              <linearGradient id="paint0_linear" x1="23.4402" y1="32.0527" x2="23.4402" y2="11.0528" gradientUnits="userSpaceOnUse">
                <stop stopColor="#5558FF" />
                <stop offset="1" stopColor="#00C0FF" />
              </linearGradient>
              <linearGradient id="paint1_linear" x1="19.0918" y1="24.6289" x2="19.0918" y2="18.4766" gradientUnits="userSpaceOnUse">
                <stop stopColor="#ADDCFF" />
                <stop offset="0.5028" stopColor="#EAF6FF" />
                <stop offset="1" stopColor="#EAF6FF" />
              </linearGradient>
              <clipPath id="clip0">
                <rect width="21" height="21" fill="white" transform="translate(11.0527 11.0527)" />
              </clipPath>
            </defs>
          </svg>
        </MainSendNew>) : null}
    </InputFieldContainerNew>
  );

  renderDidYouKnowTip = () => (
    <TileContainer>
      <TitleContent>
        <i className="fas fa-question" />
        <span>&nbsp;Did you know?</span>
      </TitleContent>
      <SideBarContent>
        <span>Did you know that improving your balance can improve your athletic ability?</span>
      </SideBarContent>
    </TileContainer>
  );

  acceptOrRejectRequest = (id, status) => {
    const { acceptOrRejectFriendRequest } = this.props;
    acceptOrRejectFriendRequest({ request_id: id, status: status });
  };

  renderFriendRequestModal = () => {
    const { showModal } = this.state;
    const { friendRequests } = this.props;
    return (
      <Modal show={showModal === 'requestsModal'} onHide={this.onHide}>
        <Modal.Header closeButton>
          <StyledTitle>Friend Requests</StyledTitle>
        </Modal.Header>
        <Modal.Body>
          {
            friendRequests ? friendRequests.map((request, index) => (
              <ProfileContainer key={index}>
                <Profile>
                  <div className="flex">
                    <Image src={`${ImageUrl}/${request.profile_image}`} alt={request.fullname} />
                    <span>{request.fullname}</span>
                  </div>
                </Profile>
                <Profile>
                  <button onClick={() => this.acceptOrRejectRequest(request.id, 'Accepted')}>accept</button>
                  <button onClick={() => this.acceptOrRejectRequest(request.id, 'Rejected')}>delete</button>
                </Profile>
              </ProfileContainer>
            )) :
              <NoFriendRequests>No friend requests to display</NoFriendRequests>
          }
        </Modal.Body>
      </Modal>
    )
  };

  showModal = () => {
    this.setState({
      showModal: 'requestsModal'
    })
  };

  renderFriendRequests = (requests) => (
    <TileContainer>
      <TitleContent isClickable onClick={() => this.showModal()}>
        <i className="fas fa-user" />
        <span>Friend Requests:</span>
      </TitleContent>
      {
        requests.length > 0 ?
          <SideBarContent isClickable>
            {requests.map((request, index) => (
              <div key={index} className="image-container" onClick={() => this.showModal()}>
                <Image
                  image={request.profile_image}
                  alt={request.fullname}
                />
              </div>
            ))}
            {this.renderFriendRequestModal()}
          </SideBarContent> :
          <SideBarContent><NoFriendRequests>No friend requests to show</NoFriendRequests></SideBarContent>
      }
    </TileContainer>
  );

  renderActivityFeeds = (feeds) => (
    <TileContainer>
      <TitleContent>
        <i className="fas fa-list" />
        <span>Activity Feed:</span>
        <span><span>{feeds.length}</span>&nbsp;new notifications</span>
      </TitleContent>
      {feeds.length > 0 ? feeds.map((feed, index) => (
        <SideBarContent key={index} notificationSection >
          <StyledImage left>
            <div className="middle">
              <Image image={feed.friend_image} alt={feed.friend_name} />
            </div>
            <NotificationDescription>
              {feed.friend_name} <span className=" lowerCase">{feed.action_type + ' with '}</span>
              {feed.fof_name}
            </NotificationDescription>
          </StyledImage>
        </SideBarContent>
      )) : <NoActivityFeeds>No activities to show</NoActivityFeeds>}
    </TileContainer>
  );

  onHide = () => {
    this.setState({
      showModal: '',
      imgSrc: []
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage);
    }
  }

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUploadedImage: true
        })
      }
      else {
        this.setState({
          isUploadedImage: false
        })
      }
    }
  }

  togglePostButton = (value, image) => {
    const { shoutoutText, userIdArray } = this.state;
    if (userIdArray.length !== 0) {
      if (userIdArray.length !== 0 && !_.isNull(shoutoutText)) {
        return false;
      } else
        return true;
    } else if (value && value.trim() !== '' || image !== '') {
      return false;
    }
    return true;
  };

  addPost = () => {
    const { imgSrc, mentionedUsers, users, userIdArray, inspirationQuote, taggedUser, exemplifyID } = this.state;
    const { addSocialInspiration, activeTab } = this.props;
    let inspirationData = {
      image: imgSrc.length >= 1 ? imgSrc[0] : '',
      exemplify_id: exemplifyID,
      shoutout: {
        exemplifies: mentionedUsers
      },
      description: inspirationQuote
    };
    if (!_.isEmpty(mentionedUsers)) {
      inspirationData.shoutout_user_name = `${applyHighlights(users, userIdArray, taggedUser)} - ${this.state.shoutoutText}`;
    }
    this.onHide();
    addSocialInspiration(inspirationData);
    let active = activeTab === "Company" ? "Company" : "Everybody";
    this.props.setActiveTab(active);
    this.setState({
      inspirationQuote: '',
      imgSrc: [],
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      taggedUser: '',
      userIdArray: [],
      users: [],
      mentionedUsers: [],
      shoutoutType: 0,
    })
  };

  renderAssessmentPanel = () => {
    const { history } = this.props;
    return (
      <AssessmentContainer>
        <TipInformation onClick={() => history.push('/wellness-assessment/medical')}>
          <HraIcon><img src={'/public/images/HRA_icon.svg'} /></HraIcon>
          <span>TAKE THE HEALTH RISK ASSESSMENT</span>
        </TipInformation>
      </AssessmentContainer>
    );
  };

  handlePhotoPostModal = (addCommentstatus = 0, id = '', commentId = '') => {
    this.setState((prev) => ({
      showPhotoPostModal: !prev.showPhotoPostModal,
      addCommentWithImageStatus: addCommentstatus,
      feedIdForComment: id,
      commentId: commentId
    }), () => {
      this.setState({ editPostStatus: 0, editDetails: {} })
    });
  };

  handlePhotoVideoPostModal = (addCommentstatus = 0, id = '', commentId = '') => {
    this.setState((prev) => ({
      showPhotoVideoPostModal: !prev.showPhotoVideoPostModal,
      addCommentWithImageStatus: addCommentstatus,
      feedIdForComment: id,
      commentId: commentId
    }), () => {
      this.setState({ editPostStatus: 0, editDetails: {} })
    });
    console.log("776 line \n showPhotoVideoPostModal:- ", this.state.showPhotoVideoPostModal);
    
  };

  hidePhotoVideoPostModal = (addCommentstatus = 0, id = '', commentId = '') => {
    this.setState((prev) => ({
      showPhotoVideoPostModal: !prev.showPhotoVideoPostModal,
      addCommentWithImageStatus: addCommentstatus,
      feedIdForComment: id,
      commentId: commentId,
      imgSrcArray:[]
    }), () => {
      this.setState({ editPostStatus: 0, editDetails: {}, imgSrcArray:[] })
    });
  };

  handleLikePostModal = (data) => {
    this.setState({
      feedNumber: data
    })
  };

  handleLikePostModal2 = () => {
    this.setState((prev) => ({
      showLikePostModal: !prev.showLikePostModal,
    }));
  };

  handlePeerModal = () => {
    this.setState((prev) => ({
      showPeerModal: !prev.showPeerModal,
      shoutOutData: {},
      showRecognitionSingle: false
    }));
  };

  handleTabSwitch = (index) => {
    const { callBack } = this.props;
    this.setState({
      activeLink: index
    })
    callBack(index);
  }

  showBirthdayCardModal = () => {
    this.setState((prev) => ({
      showBirthdaySideBarModal: !prev.showBirthdaySideBarModal,
      tabIndex: 0
    }
    ));
  }

  showAnniversaryCardModal = () => {
    this.setState((prev) => ({
      showBirthdaySideBarModal: !prev.showBirthdaySideBarModal,
      tabIndex: 1
    }
    ));
  }

  showAddBuddyPopUp = (ID, status) => {
    const { addBuddy } = this.props;
    this.setState((prev) => ({
      showAddBuddy: !prev.showAddBuddy,
      friendStatus: status
    }))
    addBuddy(ID);
  }

  showAddBuddyPopUp2 = () => {
    this.setState((prev) => ({
      showAddBuddy: !prev.showAddBuddy
    }))
  }

  onChangeImage = (e) => {
    let files = e.target.files;
    let length = files.length;
    for (let i = 0; i < length; i++) {
      if (checkImage(files)) {
        if ((files[i]?.size / 1000000) <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.onloadend = () => {
            this.setState({
              imgSrcArray: [...this.state.imgSrcArray, reader.result]
            },()=>this.setState({showPhotoVideoPostModal:true}))
          };
        } else {
          toast.error('Please select image file less than 20MB');
          document.getElementById('uploadPhoto') && (document.getElementById('uploadPhoto').value = '');
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById('uploadPhoto') && (document.getElementById('uploadPhoto').value = '');
      }
    }
  };

  renderView = () => {
    const { fullMonth, month, isBirthdayCard, isAnniversaryCard, handleAPICalling } = this.state;
    const { feeds, history, suggestionList, user, profileImage, listOfThingToComplate, t, SetDailyGoal, inputGoals, exemplifiesValues, birthdayList, anniversaryList, userCompany, motivationalQuote, dailyTip } = this.props;
    let showBirthdayContainer = true;
    let showAnniversaryContainer = true;
    if (birthdayList && birthdayList.today_birthday && birthdayList.today_birthday.length === 0 && birthdayList.upcoming_birthday && birthdayList.upcoming_birthday.length === 0 && birthdayList.recent_birthday && birthdayList.recent_birthday.length === 0) {
      showBirthdayContainer = false;
    }
    if (anniversaryList && anniversaryList.today_anniversary && anniversaryList.today_anniversary.length === 0 && anniversaryList.upcoming_anniversary && anniversaryList.upcoming_anniversary.length === 0 && anniversaryList.recent_anniversary && anniversaryList.recent_anniversary.length === 0) {
      showAnniversaryContainer = false;
    }
    return (
      <MainWrapper onScroll={handleAPICalling && this.onScroll}>
        <div className='mainDiv'>
          <CoreValueSideBarContainer width="26.14">
            <CoreValueMain>
              <div className='main'>{t("Your Company Core Value")}</div>
              {exemplifiesValues && exemplifiesValues.length && exemplifiesValues.map((coreValue, index)=>(
                <CoreValue key={index} margin={exemplifiesValues.length==(index+1)}>
                  <div className='image'>
                    <img src={`${ImageUrl}/${coreValue.image}`} />
                  </div>
                  <div className='values'>
                    <div className='main'>
                      <div className='name' style={{margin:!coreValue.description&&"auto auto auto 0px"}}>{this.props.t(coreValue.core_value)}</div>
                      <div className='button' onClick={()=>this.peerCallBackRecognition(coreValue)}>{this.props.t("Give")}</div>
                    </div>
                  </div>
                  <div className='info' onClick={()=>this.showRecognitionPopup(coreValue)}>
                    <img src={ImageUrl + "/social-feed/info.png"}/>
                  </div>
                </CoreValue>
              ))}
            </CoreValueMain>
          </CoreValueSideBarContainer>
          <SocialFeedsContainer width="45.6%" videoCSS>
            <CommentSectionNew>
              {this.renderInputField(`${t("What's on your mind,")} ${user && user.fname}?`, "UpdateStatus", "", profileImage)}
              <ButtonWrapperNew>
                <PostWrapperNew onClick={() => this.handlePhotoPostModal()} border={"1"}>
                  <div>
                    <LazyImage src={ImageUrl + "/social-feed/postIcon.png"}/>
                    <p>{t('Post')}</p>
                  </div>
                </PostWrapperNew>
                <PostWrapperNew onClick={() => this.handlePhotoVideoPostModal()} border={"1"}>
                  <div>
                    <LazyImage src={ImageUrl + "/social-feed/cameraIcon.png"}/>
                    <p>{t('Photo')}</p>
                  </div>
                  <input
                    id="upload-file"
                    type="file"
                    name="user"
                    multiple={true}
                    onChange={(e) => this.onChangeImage(e)}
                    accept=".jpeg, .png, .jpg"
                    hidden
                  />
                </PostWrapperNew>
                <PostWrapperNew onClick={() => this.handlePeerModal()} width="1">
                  <div>
                    <LazyImage src={ImageUrl + "/social-feed/recognition.png"}/>
                    <p>{t('Peer Recognition')}</p>
                  </div>
                </PostWrapperNew>
              </ButtonWrapperNew>
            </CommentSectionNew>
            {this.renderFeeds(feeds)}
          </SocialFeedsContainer>

          <SideBarContainer width="26.14%">
            <DailyTipCard image={dailyTip &&dailyTip.bg_image}>
              <div className='heading'>
                <div className='title'>{t("Today’s Tip")}</div>
              </div>
              <div className='dailyTipImage'>
                {dailyTip.daily_tip}
              </div>
            </DailyTipCard>
            {showBirthdayContainer && (!isNull(userCompany.companyStartDate) && userCompany.companyStartDate.user_birthday_notify === 1) && isBirthdayCard === 1 && 
              <BirthdayWishCard callback={this.showHideBirthdayPopup}/>}
            { showAnniversaryContainer && (!isNull(userCompany.companyStartDate) && userCompany.companyStartDate.show_work_anniversary === 1) && isAnniversaryCard === 1 &&
              <AnniversaryWishCard callback={this.showHideAnniversaryPopup}/>}              
            <DailyTipCard showmotivational image={motivationalQuote&& motivationalQuote.bg_image}>
              <div className='heading'>
                <div className='title'>{t("Motivational Quote")}</div>
              </div>
              <div className='dailyTipImage'>
                {this.props.t(motivationalQuote.quote)}
              </div>
            </DailyTipCard>
            <SuggestionForYouSocial suggestionList={suggestionList} history={history} showAddBuddyPopUp={this.showAddBuddyPopUp} showAddBuddyPopUp2={this.showAddBuddyPopUp} />
            {listOfThingToComplate &&
              <TaskToComplete
                fullMonth={fullMonth}
                month={month}
                SetDailyGoal={SetDailyGoal}
                inputGoals={inputGoals}
                history={history}
                listOfThingToComplate={listOfThingToComplate}
                displayDeviceModal={this.displayDeviceModal}
                padding={1}
              />}
            {this.state.showViewCommentModal && this.state.specialFeed ? <ViewCommentModal onClose={this.renderViewCommentModal} showModal={this.state.showViewCommentModal} feed={this.state.specialFeed}
              description={this.state.specialFeed.description}
              taggedUsers={this.state.specialFeed.shoutout_user_name}
              getCommentsForPost={this.getCommentsForPost}
              changeEditDeletePopup={this.changeEditDeletePopup}
              editAndDelete={this.state.editAndDelete}
              openEditAndDelete={this.state.openEditAndDelete}
              profileImage={this.props.profileImage}
              updationList="response"
              commentCount={this.state.specialFeed.comment_count}
              history={this.props.history}
              enableTrigger={this.props.enableTrigger}
              key={`feed-${this.state.specialFeed.id}`}
              onChange={this.onChange}
              handleFeed={this.handleFeed}
              handleModal={this.handleModal}
              feedNumber={this.state.feedNumber}
              data={this.props.postLikeDetails}
              exemplifiesValues={this.props.exemplifiesValues}
              showRecognition={this.showRecognition}
              value={this.state.value}
              id={this.state.id}
              image={this.state.image}
              feedNo={this.state.feedNo}
              updateFeedsLike={this.props.updateFeedsLike}
              storedFeeds={this.props.feeds}
              editPost={this.editPostModel}
              socialCallBack={this.props.socialCallBack}
              handlePhotoPostModal={this.handlePhotoPostModal}
              comments={this.props.comments}
              postReply={this.props.postReplyOnComment}
              replySuccess={this.props.replySuccess}
            /> : null}
          </SideBarContainer>
        </div>
      </MainWrapper>
    );
  };

  handleSelect = (key) => {
    this.props.setActiveTab(key);
  };

  peerCallBack = (data, index, filter) => {
    let obj = {};
    obj = data;
    if (index === 0) {
      obj.core_value = "Happy Birthday";
      obj.image = "social/birthdayShoutOut.png";
      obj.examplifyId = 19;
      //obj.icon = "social-recognition/birthday.png",
      obj.name = filter?data.full_name:data.name,
      obj.uid = data.uid
    } else {
      obj.core_value = "Work Anniversary";
      obj.image = "social/anniversaryShoutOut.png";
      obj.examplifyId = 20;
      //obj.icon = "social-recognition/anniversary.png";
      obj.name = filter?data.full_name:data.name,
      obj.uid = data.uid
    }

    this.setState((prev) => ({
      shoutOutData: { ...obj },
      showPeerModal: !prev.showPeerModal,
      showRecognitionSingle: false
      // showBirthdaySideBarModal: !prev.showBirthdaySideBarModal
    }))
  }

  peerCallBackRecognition = (data) => {
    let obj = {};
    obj = data;
    obj.core_value = data.core_value
    obj.image = data.image;
    obj.examplifyId = data.id;
    this.setState((prev) => ({
      shoutOutData: { ...obj },
      showPeerModal: !prev.showPeerModal,
      showRecognitionSingle: !prev.showRecognitionSingle
    }))
  }

  showRecognition = (feedNumber) => {
    this.setState((prev) => ({
      showRecognitionModal: !prev.showRecognitionModal,
      feedNo: feedNumber
    }));
  }

  onShoutOutSelect = (value, id, image) => {
    this.setState({
      value: value,
      id: id,
      image: image
    })
  }
  handleScrollTrigger = () => {
    this.setState((prev) => ({ handleAPICalling: !prev.handleAPICalling }));
  }
  render() {
    const { showEditDeletePopup, editDetails, showConnectDevice, showBirthdaySideBarModal, editPostStatus, showAddBuddy, addCommentWithImageStatus, feedIdForComment, commentId, /*handleAPICalling,*/ imgSrcArray, isAnniversaryCard, isBirthdayCard, showBirthdayWishPopup, showAnniversaryWishPopup, showWarningRecognition, friendStatus, showModalRecogniton, recognitionData } = this.state;
    const { profileImage, enableTrigger, revokeDevice, devicesDisconnected, devices, buddyDetails, socialCallBack } = this.props;
    if (!profileImage) {
      return <Waiting />
    }
    return (
      <div  style={{ width: '100%', height: "1600px" }}>
        {showConnectDevice ? <ConnectDeviceSideBar innerRef={this.myref} devices={devices} revokeDevice={revokeDevice} devicesDisconnectedList={devicesDisconnected} toggleConnectDevices={this.toggleConnectDevices} showModal={showConnectDevice} /> : null}
        {showBirthdaySideBarModal ? <BirthdaySideBar index={this.state.tabIndex} peerCallBack={this.peerCallBack} showModal={showBirthdaySideBarModal} closeModal={this.showBirthdayCardModal} isAnniversaryCard={isAnniversaryCard} isBirthdayCard={isBirthdayCard}/> : null}
        {showBirthdayWishPopup&&<BirthdayWishPopup show={showBirthdayWishPopup} history={this.props.history} hidePopup={this.showHideBirthdayPopup} peerCallBack={this.peerCallBack} showBirthday="1"/>}
        {showAnniversaryWishPopup&&<BirthdayWishPopup show={showAnniversaryWishPopup} history={this.props.history} hidePopup={this.showHideAnniversaryPopup} peerCallBack={this.peerCallBack} />}
        {this.renderView()}
        {
          this.state.showPhotoPostModal && <PhotoAndPostFlow
            showModal={this.state.showPhotoPostModal}
            onClose={this.handlePhotoPostModal}
            history={this.props.history}
            feeds={this.props.feeds}
            friendRequests={this.props.friendRequests}
            acceptOrRejectFriendRequest={this.props.acceptOrRejectFriendRequest}
            setActiveTab={this.props.setActiveTab}
            activityFeeds={this.props.activityFeeds}
            loadMoreFeeds={(pageNumber) => this.props.loadMoreFeeds(pageNumber)}
            wellnessExpert={this.props.wellnessExpert}
            role={this.props.role}
            activeTab={this.state.filterType}
            enableTrigger={true}
            companyId={this.props.companyId}
            profileImage={this.props.profileImage}
            addPost={this.addPost}
            editDetails={editDetails}
            editPostStatus={editPostStatus}
            addCommentWithImageStatus={addCommentWithImageStatus}
            feedIdForComment={feedIdForComment}
            commentId={commentId}
            showRecognitionPopup={this.recognitionWarningPopup}
            hidePhotoVideoPopup={this.handlePhotoPostModal}
            handlePeerModal={this.handlePeerModal}
          />
        }
        {
          this.state.showPhotoVideoPostModal && imgSrcArray.length>0 &&<PhotoAndVideoPostFlow
            showModal={this.state.showPhotoVideoPostModal}
            onClose={this.hidePhotoVideoPostModal}
            history={this.props.history}
            feeds={this.props.feeds}
            friendRequests={this.props.friendRequests}
            acceptOrRejectFriendRequest={this.props.acceptOrRejectFriendRequest}
            setActiveTab={this.props.setActiveTab}
            activityFeeds={this.props.activityFeeds}
            loadMoreFeeds={(pageNumber) => this.props.loadMoreFeeds(pageNumber)}
            wellnessExpert={this.props.wellnessExpert}
            role={this.props.role}
            activeTab={this.state.filterType}
            enableTrigger={true}
            companyId={this.props.companyId}
            profileImage={this.props.profileImage}
            addPost={this.addPost}
            editDetails={editDetails}
            editPostStatus={editPostStatus}
            addCommentWithImageStatus={addCommentWithImageStatus}
            feedIdForComment={feedIdForComment}
            commentId={commentId}
            imgSrcArray={imgSrcArray}
            showRecognitionPopup={this.recognitionWarningPopup}
            hidePhotoVideoPopup={this.hidePhotoVideoPostModal}
            handlePeerModal={this.handlePeerModal}
          />
        }
        {
          this.state.showPeerModal && <PeerRecognition
            onClose={this.handlePeerModal}
            showModal={this.state.showPeerModal}
            users={this.state.users}
            history={this.props.history}
            feeds={this.props.feeds}
            friendRequests={this.props.friendRequests}
            acceptOrRejectFriendRequest={this.props.acceptOrRejectFriendRequest}
            setActiveTab={this.props.setActiveTab}
            activityFeeds={this.props.activityFeeds}
            loadMoreFeeds={(pageNumber) => this.props.loadMoreFeeds(pageNumber)}
            wellnessExpert={this.props.wellnessExpert}
            role={this.props.role}
            activeTab={this.state.filterType}
            enableTrigger={true}
            companyId={this.props.companyId}
            shoutOut={this.state.shoutOutData}
            showRecognitionSingle={this.state.showRecognitionSingle}
          />
        }
        <Suspense fallback={<Waiting/>}>
          {
            this.state.showLikePostModal && <LikePopUp onClose={() => this.handleLikePostModal2()} showModal={this.state.showLikePostModal} feedNumber={this.state.feedNumber} />
          }
        </Suspense>
        <Suspense fallback={<Waiting/>}>
          {/* {showAddBuddy ? <AddBuddyPopUp showModal={showAddBuddy} onClose={this.showAddBuddyPopUp2} buddyDetails={buddyDetails} hideCancel={0} socialCallBack={socialCallBack} friendStatus={this.state.friendStatus} /> : null} */}

          {showAddBuddy&&<DeletePostPopUp
            showModal={showAddBuddy}
            onClose={this.showAddBuddyPopUp2}
            buddyDetails={buddyDetails}
            hideCancel={0}
            socialCallBack={socialCallBack}
            friendStatus={this.state.friendStatus}
            showpending= {friendStatus == "Pending"}
            showAddBuddy={isNull(friendStatus)}
            pendingStatus={friendStatus == "Pending"}
          />}
          <EditPostPopUp
            feed={editDetails}
            showModal={showEditDeletePopup == 'edit'}
            onClose={this.onHideEditDeletePopup}
            updationList="response"
            profileImage={this.props.profileImage}
            enableTrigger={enableTrigger}
          />
        </Suspense>
        <Suspense fallback={<Waiting/>}>
          <DeletePostPopUp
            feed={editDetails}
            showModal={showEditDeletePopup == 'delete'}
            onClose={this.onHideEditDeletePopup}
            updationList="response"
            title={this.props.t("DELETE POST")}
            subContent={this.props.t("This action cannot be undone.")}
          />
        </Suspense>
        {<WarningPopup
          showModal={showWarningRecognition}
          onClose={this.recognitionWarningPopup}
          hidePhotoVideoPopup={this.handlePhotoPostModal}
          handlePeerModal={this.handlePeerModal}
        />
        }
        {showModalRecogniton&&<RecognitionDescription
          showModal={showModalRecogniton}
          onClose={this.onCloseRecognition}
          recognitionData={recognitionData}
        />}
      </div>
    )
  }
}

SocialFeeds.propTypes = {
  feeds: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  addSocialInspiration: PropTypes.func.isRequired,
  friendRequests: PropTypes.array.isRequired,
  acceptOrRejectFriendRequest: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  activityFeeds: PropTypes.array.isRequired,
  comments: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  fetchCommentsOnPost: PropTypes.func.isRequired,
  postReplyOnComment: PropTypes.func.isRequired,
  replySuccess: PropTypes.string,
  loadMoreFeeds: PropTypes.func.isRequired,
  wellnessExpert: PropTypes.object,
  iswbaTaken: PropTypes.bool.isRequired,
  profileImage: PropTypes.string,
  fetchUserProfileImage: PropTypes.func,
  count: PropTypes.number,
  role: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  fetchUsersToShoutout: PropTypes.func,
  userCompany: PropTypes.object.isRequired,
  employeeData: PropTypes.array,
  enableTrigger: PropTypes.bool,
  userPermissions: PropTypes.array,
  companyId: PropTypes.number.isRequired,
  devices: PropTypes.array,
  revokeDevice: PropTypes.func.isRequired,
  devicesDisconnected: PropTypes.array,
  fetchConnectedDevices: PropTypes.func.isRequired,
  dailyTip: PropTypes.object,
  getSuggestionsList: PropTypes.func,
  suggestionList: PropTypes.array,
  user: PropTypes.object,
  callBack: PropTypes.func,
  getPostLikeDetails: PropTypes.func,
  postLikeDetails: PropTypes.array,
  userBirthdayAndAnniversary: PropTypes.func,
  birthdayAnniversaryWishModalStatus: PropTypes.object,
  getUser: PropTypes.func,
  userName: PropTypes.string,

  getCompanyExemplifiesValues: PropTypes.func,
  exemplifiesValues: PropTypes.array,
  updateFeedsLike: PropTypes.func,
  socialPosts: PropTypes.array,
  socialCallBack: PropTypes.func,
  buddyDetails: PropTypes.array,
  addBuddy: PropTypes.func,
  getWorkAnniversary: PropTypes.func,
  getAmigoBirthday: PropTypes.func,
  birthdayList: PropTypes.object,
  anniversaryList: PropTypes.object,
  fetchListOfThingsToComplete: PropTypes.func,
  listOfThingToComplate: PropTypes.object,
  t: PropTypes.func,
  SetDailyGoal: PropTypes.func,
  inputGoals: PropTypes.object,
  getProfileInputActivityGoals: PropTypes.func,
  getBirthdaysList: PropTypes.func,
  getInspirationPost: PropTypes.func,
  inspirationPost: PropTypes.array,
  location: PropTypes.object.isRequired,
  motivationalQuote: PropTypes.object
};

const mapStateToProps = (state) => ({
  isLoading: state.social.isLoading,
  comments: state.social.comments,
  replySuccess: state.social.replySuccess,
  profileImage: state.profileData.profileImage,
  count: state.social.count,
  employeeData: state.peopleHome.employeeData,
  userCompany: state.profileData.userCompany,
  userPermissions: state.profileData.userPermissions,
  devices: state.wellnessDashboard.devices,
  suggestionList: state.social.suggestionList,
  devicesDisconnected: state.profileData.devicesDisconnected,
  dailyTip: state.profileData.dailyTip,
  postLikeDetails: state.social.postLikeDetails,
  userName: state.profileData.firstName,
  exemplifiesValues: state.social.exemplifiesValues,
  socialPosts: state.social.response,
  buddyDetails: state.social.buddyDetails,
  birthdayList: state.social.birthdayList,
  anniversaryList: state.profileData.workAnniversaryList,
  listOfThingToComplate: state.profileData.listOfThingToComplate,
  inputGoals: state.profileData.inputGoals,
  inspirationPost: state.social.inspirationPost,
  motivationalQuote: state.profileData.motivationalQuote
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersToShoutout: (data, cb) => dispatch(getUsersToShoutout(data, cb)),
  addSocialInspiration: (inspirationData) => dispatch(addSocialInspiration(inspirationData)),
  fetchCommentsOnPost: (id) => dispatch(getCommentsOnPost(id)),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  postReplyOnComment: (commentId, reply, postId, shoutout, shoutOutUserName, exemplify_id, image) => dispatch(addCommentReplyOnPost(commentId, reply, postId, shoutout, shoutOutUserName, exemplify_id, image)),
  getSuggestionsList: () => dispatch(getSuggestionsList()),
  fetchConnectedDevices: () => dispatch(getListOfConnectedDevices()),
  revokeDevice: (name) => dispatch(revokeDevice(name)),
  getPostLikeDetails: (data) => dispatch(getPostLikeDetails(data)),
  userBirthdayAndAnniversary: () => dispatch(userBirthdayAndAnniversary()),
  getUser: () => dispatch(getUser()),
  getCompanyExemplifiesValues: () => dispatch(getCompanyExemplifiesValues()),
  addBuddy: (data) => dispatch(addBuddy(data)),
  getAmigoBirthday: () => dispatch(getAmigoBirthday()),
  getWorkAnniversary: () => dispatch(getWorkAnniversary()),
  fetchListOfThingsToComplete: () => dispatch(getListOfThingsToCompleteAPI()),
  SetDailyGoal: (dailyGoalData) => dispatch(SetDailyGoal(dailyGoalData)),
  getProfileInputActivityGoals: (dailyGoalData) => dispatch(getProfileInputActivityGoals(dailyGoalData)),
  getBirthdaysList: () => dispatch(getBirthdaysList()),
  getInspirationPost: (id) => dispatch(getInspirationPost(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SocialFeeds));
